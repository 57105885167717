<template>
  <div class="story_page">
    <div class="banner">
      <img src="@/assets/images/banzhuan/story/banner1.jpg" alt="" />
    </div>
    <div class="content">
      <div class="item">
        <div class="desc">
          <div class="desc_left">
            <div class="title">羽素品牌使命</div>
            <div class="eng_title">BRAND MISSION</div>
          </div>
          <div class="desc_right">
            羽素多年来专注于<br />
            痘痘/痘印/敏感等肌肤问题研究<br />
            科学分析X专家专研X量肤搭配X顾问式服务
          </div>
        </div>
        <div class="img">
          <img src="@/assets/images/banzhuan/story/story1.jpg" alt="" />
        </div>
      </div>
      <div class="item">
        <div class="desc">
          <div class="desc_left">
            <div class="title">品牌价值观</div>
            <div class="eng_title">BRAND VALUES</div>
          </div>
          <div class="desc_right">
            我们坚信<br />
            “每一个青春，都有追求美的权利”<br />
            “Healthy skin，beautiful life”
          </div>
        </div>
        <div class="img">
          <img src="@/assets/images/banzhuan/story/story2.jpg" alt="" />
        </div>
      </div>

      <div class="item">
        <div class="desc">
          <div class="desc_left">
            <div class="title">科学分析</div>
            <div class="eng_title">SCIENTIFIC</div>
          </div>
          <div class="desc_right">
            融汇科学技术力量<br />
            多维分析皮肤问题
          </div>
        </div>
        <div class="img">
          <img src="@/assets/images/banzhuan/story/story3.jpg" alt="" />
        </div>
      </div>
      <div class="item">
        <div class="desc">
          <div class="desc_left">
            <div class="title">专家专研</div>
            <div class="eng_title">EXPERTS</div>
          </div>
          <div class="desc_right">
            创新科研结合实验结果<br />
            赋予配方安全高质高能
          </div>
        </div>
        <div class="img">
          <img src="@/assets/images/banzhuan/story/story4.jpg" alt="" />
        </div>
      </div>

      <div class="item">
        <div class="desc">
          <div class="desc_left">
            <div class="title">量肤搭配</div>
            <div class="eng_title">MEASURING</div>
          </div>
          <div class="desc_right">
            结合真实肌肤问题综合考量<br />
            提供针对性的量肤解决方案
          </div>
        </div>
        <div class="img">
          <img src="@/assets/images/banzhuan/story/story5.jpg" alt="" />
        </div>
      </div>
 2
      <div class="item">
        <div class="desc">
          <div class="desc_left">
            <div class="title">1v1顾问式服务</div>
            <div class="eng_title">1v1 SERVICE</div>
          </div>
          <div class="desc_right">
            专业贴心，持续陪伴<br />
            共同迎接肌肤的改变
          </div>
        </div>
        <div class="img">
          <img src="@/assets/images/banzhuan/story/story61.jpg" alt="" />
        </div>
      </div>
      <div class="item yellow">
        无论你有什么样的肌肤问题<br />
        羽素 羽你一起，做回自己
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Story',
  components: {},
  data: function () {
    return {}
  },
}
</script>
<style scoped lang='scss'>
.story_page {
  padding-bottom: 150px;
  .content {
    padding: 0 240px;
    position: relative;
    margin-top: -5px;
    .item {
      &:first-child {
        background: #ffe900;
      }
      &.yellow {
        background: #ffe900;
        height: 500px;
        font-size: 66px;
        line-height: 2;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .desc {
      padding: 150px 0 100px 0;
      text-align: center;
      display: flex;
      line-height: 1.5;
      .desc_left {
        flex: 1;
        .title {
          font-size: 52px;
          color: #3e3a39;
        }
        .eng_title {
          font-size: 33px;
          color: #3e3a39;
        }
      }
      .desc_right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        color: #3e3a39;
        border-left: 1px solid #3e3a39;
      }
    }
  }
}
</style>
